import React from "react";
import PageLayout from "../components/pagelayout";
import SEO from "../components/seo";
import { TwitterTimelineEmbed } from "react-twitter-embed";

const Tweets = () => (
  <PageLayout>
    <SEO title="Tweets" />
    <div style={{ margin: "10px 20px" }}>
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName="picker"
        options={{ width: 600 }}
      />
    </div>
  </PageLayout>
);

export default Tweets;
